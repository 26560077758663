<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Reset Password v1 -->
            <b-card class="mb-0">
                <!-- logo -->
                <b-link class="brand-logo" href="/">
                    <b-img :src="logo" class="brand-logo-image" alt="soclib logo" width="120" />
                </b-link>

                <!-- alert -->
                    <div v-if="alertMessages.length > 0">
                        <b-alert :variant="alertType" :show="alertVisibility">
                            <div class="alert-body">
                                <span
                                    class="d-block"
                                    v-for="(
                                        alertMessage, index
                                    ) in alertMessages"
                                    :key="index"
                                    :style="
                                        alertMessage.length - 1 == index
                                            ? 'margin-top: 4px'
                                            : ''
                                    "
                                >
                                    <small v-html="alertMessage"></small>
                                </span>
                            </div>
                        </b-alert>
                    </div>
                    <!-- /alert -->

                <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                    Reset Password</b-card-title
                >
                <b-card-text class="mb-2">
                    Enter new password, the password you want to set must
                    confirmed
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                    <b-form
                        method="POST"
                        class="auth-reset-password-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- password -->
                        <b-form-group
                            label="New Password"
                            label-for="reset-password-new"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                vid="Password"
                                rules="required|password"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="reset-password-new"
                                        v-model="password"
                                        :type="password1FieldType"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        class="form-control-merge"
                                        name="reset-password-new"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="password1ToggleIcon"
                                            @click="togglePassword1Visibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- confirm password -->
                        <b-form-group
                            label-for="reset-password-confirm"
                            label="Confirm Password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Confirm Password"
                                rules="required|confirmed:Password"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="reset-password-confirm"
                                        v-model="cPassword"
                                        :type="password2FieldType"
                                        class="form-control-merge"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="reset-password-confirm"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="password2ToggleIcon"
                                            @click="togglePassword2Visibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button block type="submit" variant="primary">
                            Set New Password
                        </b-button>
                    </b-form>
                </validation-observer>

                <p class="text-center mt-2">
                    <b-link :to="{ name: 'auth-login-v1' }">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </p>
            </b-card>
            <!-- /Reset Password v1 -->
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BAlert,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BFormInput,
    BButton,
    BImg,
} from "bootstrap-vue";
import _ from 'lodash';
import store from "@/store/index";
import { required } from "@validations";
import { heightFade } from "@core/directives/animations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BButton,
        BCardTitle,
        BCardText,
        BForm,
        BImg,
        BAlert,
        BFormGroup,
        BInputGroup,
        BLink,
        BFormInput,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        "height-fade": heightFade,
    },
    data() {
        return {
            userEmail: "",
            cPassword: "",
            password: "",
            // validation
            required,

            // alert properties
            alertMessages: [],
            alertType: undefined,
            alertVisibility: false,

            // Toggle Password
            password1FieldType: "password",
            password2FieldType: "password",
        };
    },
    computed: {
        password1ToggleIcon() {
            return this.password1FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        password2ToggleIcon() {
            return this.password2FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        email() {
            return localStorage.getItem('fp_email');
        },
        token() {
            return this.$route.params.token;
        },
        logo() {
            return require("@/assets/images/logo/logo_158.png")
        },
    },
    methods: {
        togglePassword1Visibility() {
            this.password1FieldType =
                this.password1FieldType === "password" ? "text" : "password";
        },
        togglePassword2Visibility() {
            this.password2FieldType =
                this.password2FieldType === "password" ? "text" : "password";
        },
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.resetPassword();
                }
            });
        },
        resetPassword() {
            const params = {
                email: this.email,
                token: this.token,
                password: this.password,
                password_confirmation: this.cPassword
            };
            this.$http
                .post(`${process.env.VUE_APP_URL}/password/reset`, params)
                .then((response) => {
                    this.$router
                        .replace({
                            name: 'auth-login'
                        })
                        .then(() => {
                            localStorage.removeItem('fp_email');
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: `Reset Password`,
                                    icon: "CoffeeIcon",
                                    variant: "success",
                                    text: response.data,
                                },
                            });
                        });
                })
                .catch((error) => {
                    this.alertType = "danger";
                    this.alertVisibility = true;
                    this.alertMessages = [error.response.data];
                    this.isInProcess = false;
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
